import React from 'react'
import PropTypes from 'prop-types'
import defaults from '@config/defaults'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { useTheme } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import LandingPageHeader from '@components/landing-page-header/index'
import ErrorBoundary from '@components/error/boundary'
import Hero from '@components/hero-event-details'
import LiveLeaderboardNavigation from '@components/live-leaderboard-navigation/index'
import { Box, Divider } from '@material-ui/core'
import PartnerLogos from '@components/partner-logos'
import RelevantArticles from '@components/relevant-articles/index'
import EventLegacySpotlight from '@components/event-legacy-spotlight/event-legacy-spotlight'
import SrOnly from '@components/atoms/sr-only'
import { LeaderboardQuery } from '@components/leaderboard/index'
import LeaderboardHeadline from '@components/leaderboard/leaderboard-headline'
import MarketingFormEmbed from '@components/marketing-form-embed'
import useStyles from './event.styles'

const Event = (props) => {
  const {
    slug,
    headline,
    heroImage,
    logo,
    eventHeadline,
    eventRichText,
    startDate,
    endDate,
    course,
    location,
    promotedLinks,
    relevantStoriesCtaText,
    relevantStoriesCtaLink,
    relatedStoriesCollection,
    relatedFeature,
    newsletterSignup,
    fullLeaderboardUrl,
    fullCoverageUrl,
    legacySpotlightHeadline,
    legacySpotlightRichText,
    legacySpotlightImage,
    partnerListsCollection,
    showLeaderboard,
    leaderboardsCollection,
    externalLeaderboard,
  } = props

  const sortedPartners = partnerListsCollection.items.sort((a, b) => a.partnerTier - b.partnerTier)
  const multiple = sortedPartners.length > 2
  const styles = useStyles({ multiple })
  const router = useRouter()
  const theme = useTheme()

  const now = DateTime.local()
  const isLive = (now >= DateTime.fromISO(startDate)) && (now <= DateTime.fromISO(endDate))
  const hasLegacySpotlight = !!legacySpotlightHeadline && !!legacySpotlightRichText && !!legacySpotlightImage
  const showLiveBanner = (fullCoverageUrl || fullLeaderboardUrl) && showLeaderboard === 'showButton' && isLive

  // add "related feature" to the related stories list
  const prappendCollection = () => {
    if (relatedFeature) {
      if (relatedStoriesCollection.total + 1 > relatedStoriesCollection.items.length) {
        relatedStoriesCollection.items.push(relatedFeature)
        relatedStoriesCollection.items.sort((a, b) => new Date(b.sys?.firstPublishedAt) - new Date(a.sys?.firstPublishedAt))
      }
    }
    return relatedStoriesCollection.items
  }
  const relatedCollection = prappendCollection()

  if (!router) return null

  return (
    <div className={styles.root}>
      <NextSeo
        noindex={defaults.noindexNofollow}
        nofollow={defaults.noindexNofollow}
        title={headline}
        description={eventHeadline}
        canonical={`${defaults.baseUrl}events`}
        openGraph={{
          title: headline,
          type: 'website',
          url: `${defaults.baseUrl}${router.asPath.substring(1)}`, // trimming string because of /
          images: [{ url: (logo && logo.url) }],
        }}
      />
      <LandingPageHeader headline={headline} />
      <ErrorBoundary>
        <Hero
          headline={headline}
          heroImage={heroImage}
          logo={logo}
          eventHeadline={eventHeadline}
          eventRichText={eventRichText}
          startDate={startDate}
          endDate={endDate}
          course={course}
          location={location}
          promotedLinks={promotedLinks}
        />
      </ErrorBoundary>
      {showLiveBanner && leaderboardsCollection.items[0].tournamentId && (
        <LiveLeaderboardNavigation
          slug={slug}
          fullLeaderboardUrl={fullLeaderboardUrl}
          fullCoverageUrl={fullCoverageUrl}
          tournamentId={leaderboardsCollection.items[0].tournamentId}
        />
      )}
      <ErrorBoundary>
        {showLeaderboard === 'showLeaderboardPreview' && (
          <Box py={4} className={styles.leaderboardContainer}>
            {leaderboardsCollection.items && leaderboardsCollection.items.map(item => (
              item.tournamentId && item.feedId && (
                <div className={styles.leaderboardContainer} key={item.tournamentId}>
                  <LeaderboardHeadline {...item} />
                  <LeaderboardQuery {...item} id={item.tournamentId} limit={Number(item.leaderboardPlaces) || null} enableScorecard />
                </div>
              )
            ))}
          </Box>
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        {showLeaderboard === 'showExternalLeaderboard' && externalLeaderboard && (
          <Box pt={[0, 2]} pb={4}>
            <LeaderboardHeadline {...externalLeaderboard} />
          </Box>
        )}
      </ErrorBoundary>
      {!showLiveBanner && hasLegacySpotlight && (
        <ErrorBoundary>
          <Box className={styles.legacyWrapper}>
            <EventLegacySpotlight
              legacySpotlightHeadline={legacySpotlightHeadline}
              legacySpotlightImage={legacySpotlightImage}
              legacySpotlightRichText={legacySpotlightRichText}
            />
          </Box>
        </ErrorBoundary>
      )}
      {relatedCollection?.length > 0 && (
        <ErrorBoundary>
          <Box className={styles.articlesWrapper} bgcolor="white">
            <RelevantArticles
              headline="Relevant Stories"
              storyCollection={{ items: relatedCollection }}
              ctaText={relevantStoriesCtaText}
              link={relevantStoriesCtaLink}
            />
          </Box>
        </ErrorBoundary>
      )}
      {newsletterSignup && (
        <ErrorBoundary>
          <Box pb={8} pt={2} bgcolor="white">
            <MarketingFormEmbed {...newsletterSignup} />
          </Box>
        </ErrorBoundary>
      )}
      {showLiveBanner && hasLegacySpotlight && (
        <ErrorBoundary>
          <EventLegacySpotlight
            legacySpotlightHeadline={legacySpotlightHeadline}
            legacySpotlightImage={legacySpotlightImage}
            legacySpotlightRichText={legacySpotlightRichText}
          />
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <Box className={styles.logoSectionWrapper}>
          <SrOnly>
            <h2>{`Sponsors and suppliers of: ${headline}`}</h2>
          </SrOnly>
          {sortedPartners[0] && (
            <Box className={`${styles.topSliderContainer} ${styles.sliderContainer}`}>
              <PartnerLogos
                logoCollection={sortedPartners[0].partnerLogosCollection}
                headline={sortedPartners[0].headline}
              />
            </Box>
          )}
          {sortedPartners[1] && (
            <Box className={styles.bottomWrapper}>
              <Box className={`${styles.bottomSliderContainer} ${styles.sliderContainer}`}>
                {sortedPartners.map((el, i) => {
                  return i > 0
            && (
              <React.Fragment key={el.sys.id}>
                <PartnerLogos
                  logoCollection={el.partnerLogosCollection}
                  headline={el.headline}
                />
                <Divider className={i === sortedPartners.length - 1 ? `${styles.hide} ${styles.divider}` : styles.divider} />
              </React.Fragment>
            )
                })}
              </Box>
            </Box>
          )}
        </Box>
      </ErrorBoundary>
    </div>
  )
}

Event.propTypes = {
  slug: PropTypes.string,
  headline: PropTypes.string,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  logo: PropTypes.shape({
    url: PropTypes.string,
  }),
  eventHeadline: PropTypes.string,
  eventRichText: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  course: PropTypes.string,
  location: PropTypes.string,
  promotedLinks: PropTypes.object,
  relevantStoriesCtaText: PropTypes.string,
  relevantStoriesCtaLink: PropTypes.string,
  legacySpotlightHeadline: PropTypes.string,
  legacySpotlightRichText: PropTypes.shape({}),
  fullLeaderboardUrl: PropTypes.string,
  fullCoverageUrl: PropTypes.string,
  relatedStoriesCollection: PropTypes.shape({
    items: PropTypes.array,
    total: PropTypes.number,
    __typename: PropTypes.string,
  }),
  relatedFeature: PropTypes.shape({}),
  newsletterSignup: PropTypes.shape({
    headline: PropTypes.string,
    digiohFormId: PropTypes.string,
    background: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  legacySpotlightImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  partnerListsCollection: PropTypes.object,
  showLeaderboard: PropTypes.string,
  leaderboardsCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  externalLeaderboard: PropTypes.object,
}

Event.defaultProps = {}

export default Event
