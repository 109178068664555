import richTextFragment from '@components/renderers/rich-text.fragment'
import storyCardFragment from '@schema/story-card'
import { leaderboardFragment } from '@layouts/leaderboard/leaderboard-fragment'
import { externalLeaderboardFragment } from '@layouts/leaderboard-landing/leaderboard-landing-query'
import { marketingFormEmbedFragment } from '@components/marketing-form-embed/marketing-form-embed.fragment'

const eventFragment = `
  slug
  headline
  heroImage {
    url
  }
  logo {
    url
  }
  eventHeadline
  eventRichText {
    ${richTextFragment}
  }
  startDate
  endDate
  course
  location
  promotedLinks {
    headline
    image {
      url
    }
    linksCollection {
      items {
        __typename
        ... on NavLink {
          text
          link
          externalLink
        }
      }
    }
  }
  relevantStoriesCtaText
  relevantStoriesCtaLink
  relatedFeature {
    ... on Story {
      ${storyCardFragment}
    }
  }
  relatedStoriesCollection(limit: 5) {
    total
    items {
      ... on Story {
        ${storyCardFragment}
      }
    }
  }
  fullCoverageUrl
  fullLeaderboardUrl
  newsletterSignup {
    ${marketingFormEmbedFragment}
  }
  legacySpotlightHeadline
  legacySpotlightRichText {
    ${richTextFragment}
  }
  legacySpotlightImage {
    url
  }
  partnerListsCollection {
    items {
      sys {
        id
      }
      headline
      partnerTier
      partnerLogosCollection {
        items {
          url
          title
          sys {
           id
          }
        }
      }
    }
  }
  showLeaderboard
  leaderboardsCollection {
    items {
      ${leaderboardFragment}
    }
  }
  externalLeaderboard {
    ${externalLeaderboardFragment}
  }
`

export default eventFragment
